/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
// Local UI components

// Style
import './index.scss';
// asstes

import Image from '../../../shared/Image';
import FormButton from '../../../shared/UIKit/FormButton';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Slogan() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */
  const { t } = useTranslation();
  const { title1, title2, description, button, link } = t('slogan', {
    returnObjects: true,
  });
  return (
    <Container className="training-space-slogan">
      <Row align="center" justify="center">
        <Col lg={6} md={12}>
          <div className="training-space-slogan-img">
            <Image src="GroupElife.png" alt="Elife" />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div className="training-space-slogan-text">
            <div className="training-space-slogan__title">
              <span>{title1}</span>
              {title2}
            </div>
            <div className="training-space-slogan__description">
              {description}
            </div>
            <FormButton text={button} navigationlink={link} />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

Slogan.propTypes = {};

Slogan.defaultProps = {};

export default Slogan;
