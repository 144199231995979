/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import { useTranslation, language } from 'react-i18next';
import axios from 'axios';

// UI lib comoponents
import { Col, Container, Row } from 'react-grid-system';

// Local UI components
import Program from '../../../shared/UIKit/Program';
import SearchInput from '../../../shared/UIKit/SearchInput';
import ProgramButton from '../../../shared/UIKit/ProgramButton';

// Redux actions and selectors

// Helpers & utils
import { ProgramEndPoint } from '../../../shared/APIs';

// Style
import './index.scss';
import '../../../shared/styles/global.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Programs() {
  /* ******************************** CONSTANTS ******************************* */
  const locale = language === undefined ? 'fr' : language;

  // Localization:
  const { t } = useTranslation();
  const { Title, searchPlaceHoalder, buttons, buttonDetail } = t('programs', {
    returnObjects: true,
  });

  /* ********************************** HOOKS ********************************* */
  const [searchValue, setSearchValue] = useState('');
  const [selectedCategory, setSelectedCategory] = useState();

  /* ***************************** CALLBACKS ***************************** */
  // state
  //
  const [programsList, setProgramsList] = React.useState([]);

  async function getAllPrograms() {
    const {
      data: { data },
    } = await axios(ProgramEndPoint(locale));
    setProgramsList(data);
  }
  // Effect
  React.useEffect(() => {
    getAllPrograms();
  }, []);

  /* ***************************** RENDER HELPERS ***************************** */
  //
  // buttons fields:
  const ProgramsFields = buttons?.map(({ id, title, resetFilter = false }) => (
    <ProgramButton
      key={id}
      programField={title}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      resetFilter={resetFilter}
    />
  ));

  // Programs List :
  const ProgramsEls = () => {
    let currentPrograms = programsList;

    if (searchValue?.length > 0 || selectedCategory !== undefined) {
      currentPrograms = programsList?.filter(
        ({ attributes: { title, category } }) => {
          let isFound = true;
          if (selectedCategory !== undefined) {
            isFound = selectedCategory.toLowerCase() === category.toLowerCase();
          }
          if (searchValue?.length > 0 && isFound) {
            isFound = title?.toLowerCase().includes(searchValue);
          }
          return isFound;
        },
      );
    }
    return currentPrograms.map(
      ({ id, attributes: { title, description, duration, date, slug } }) => (
        <Program
          key={id}
          programName={title}
          programDesc={description}
          programDuration={duration}
          programDate={date}
          programDetail={buttonDetail}
          programUrl={slug}
        />
      ),
    );
  };

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="training-space-programs" fluid>
      <Container>
        <h2 className="training-space-programs__title">{Title}</h2>
        <Row
          justify="between"
          align="center"
          className="training-space-programs-header"
        >
          <Col lg={6} md={12}>
            <SearchInput
              buttons={buttons}
              searchPlaceHoalder={searchPlaceHoalder}
              setSearchValue={setSearchValue}
            />
          </Col>
          <Col lg={6} md={12}>
            <div className="training-space-programs-menu">{ProgramsFields}</div>
          </Col>
        </Row>
        <div className="training-space-programs-list flex-column">
          <ProgramsEls />
        </div>
      </Container>
    </Container>
  );
}

Programs.propTypes = {};

Programs.defaultProps = {};

export default Programs;
