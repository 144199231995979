/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components

import Image from '../../../shared/Image';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Partners() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State

  // Other hooks like reux or localization ...
  const { t } = useTranslation();
  const { title, partnerList } = t('partners', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */

  /* ***************************** RENDER HELPERS ***************************** */
  // get Title section
  function GetTitle() {
    return (
      <Row>
        <Col>
          <h1 className="title">{title}</h1>
        </Col>
      </Row>
    );
  }
  function GetPartners() {
    return (
      <Row className="partners-container" align="center" justify="center">
        {partnerList.map(({ id, img }) => (
          <div key={id}>
            <Image src={img} alt="partner" />
          </div>
        ))}
      </Row>
    );
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="partners" align="center" justify="center" fluid>
      <Container>
        <GetTitle />
        <GetPartners />
      </Container>
    </Container>
  );
}

Partners.propTypes = {};

Partners.defaultProps = {};

export default Partners;
