/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Slogan from '../page-components/TrainingSpace/Slogan';
import Programs from '../page-components/TrainingSpace/Programs';
import Partners from '../page-components/TrainingSpace/Partners';
// Style
import '../page-styles/TrainingSpace.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function TrainingSpace() {
  /* ********************************** HOOKS ********************************* */

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Training space" />
      <Slogan />
      <Programs />
      <Partners />
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["TrainingSpace", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default TrainingSpace;
