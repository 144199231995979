/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function ProgramButton({
  programField,
  selectedCategory,
  setSelectedCategory,
  resetFilter,
}) {
  let isActive = false;
  if (resetFilter && selectedCategory === undefined) {
    isActive = true;
  } else if (selectedCategory === programField) {
    isActive = true;
  }
  /* ******************************** RENDERING ******************************* */
  return (
    <button
      type="button"
      className={`program-btn ${isActive ? 'active' : ''}`}
      onClick={() => {
        setSelectedCategory(!resetFilter ? programField : undefined);
      }}
    >
      {programField}
    </button>
  );
}

ProgramButton.propTypes = {
  programField: PropTypes.string.isRequired,
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func,
  resetFilter: PropTypes.bool,
};

ProgramButton.defaultProps = {
  selectedCategory: undefined,
  setSelectedCategory: () => {},
  resetFilter: false,
};

export default ProgramButton;
