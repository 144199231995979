/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';

// UI lib comoponents
import { Container, Visible, Row, Col } from 'react-grid-system';

// Local UI components

// Helpers & utils

// Style
import './index.scss';
import '../../styles/global.scss';
// assets
import Certification from '../../../images/index/training-features/Certification.png';
import Arrow from '../../../images/index/Programs/arrow.png';
import ArrowWhite from '../../../images/index/Programs/arrow-white.png';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Program({ programName, programDesc, programDetail, programUrl }) {
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Visible xxl xl lg>
        <Container className="program flex" fluid>
          <img src={Certification} alt="certification-program" />
          <div className="content-section flex">
            <h3>{programName}</h3>
            <p>{programDesc}</p>
          </div>
          <Link to={`/training-detail/${programUrl}`} className="link">
            <button type="button" className="detail-btn">
              {programDetail}
              <img src={Arrow} alt="arrow-details" />
              <img src={ArrowWhite} alt="arrow-details" className="white" />
            </button>
          </Link>
        </Container>
      </Visible>
      <Visible md sm xs>
        <Container className="program">
          <Row
            justify="start"
            align="center"
            style={{ margin: '2rem 0 1.5rem' }}
          >
            <Col md={3} sm={3} xs={3} align="start" justify="center">
              <img src={Certification} alt="certification-program" />
            </Col>
          </Row>
          <div className="content-section flex">
            <h3>{programName}</h3>
            <p>{programDesc}</p>
          </div>
          <Row align="center" justify="end">
            <button
              type="button"
              className="detail-btn"
              onClick={() => navigate(`/training-detail/${programUrl}`)}
            >
              {programDetail}
              <img src={Arrow} alt="arrow-details" />
              <img src={ArrowWhite} alt="arrow-details" className="white" />
            </button>
          </Row>
        </Container>
      </Visible>
    </>
  );
}

Program.propTypes = {
  programName: PropTypes.string.isRequired,
  programDesc: PropTypes.string.isRequired,
  // programDuration: PropTypes.string.isRequired,
  // programDate: PropTypes.string.isRequired,
  programDetail: PropTypes.string.isRequired,
  programUrl: PropTypes.string.isRequired,
};

Program.defaultProps = {};

export default Program;
