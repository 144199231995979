/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

// assets
import SearchIcon from '../../../images/profiles/search-icon.svg';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SearchInput({ setSearchValue, searchPlaceHoalder }) {
  let timeout;
  function handleChange(e) {
    e.preventDefault();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearchValue(e.target.value.toLowerCase());
    }, 300);
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="search-input flex">
      <img src={SearchIcon} alt="" aria-hidden="true" />
      <label htmlFor="search-form__input">
        <input
          type="text"
          id="search-form__input"
          name="search-input"
          placeholder={searchPlaceHoalder}
          onChange={handleChange}
        />
      </label>
    </div>
  );
}

SearchInput.propTypes = {
  setSearchValue: PropTypes.func,
  searchPlaceHoalder: PropTypes.string,
};

SearchInput.defaultProps = {
  setSearchValue: () => {},
  searchPlaceHoalder: '',
};

export default SearchInput;
